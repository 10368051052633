var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "from row"
  }, [_c('div', {
    staticClass: "col-12 mb-8"
  }, [_c('ul', {
    staticClass: "nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10",
    attrs: {
      "role": "tablist"
    }
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('a', {
    staticClass: "nav-link",
    class: {
      active: _vm.fromExisting
    },
    attrs: {
      "data-toggle": "tab",
      "href": "#"
    },
    on: {
      "click": function click($event) {
        return _vm.toggleExisting(true);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Select From Database")))])]), _c('li', {
    staticClass: "nav-item"
  }, [_c('a', {
    staticClass: "nav-link",
    class: {
      active: !_vm.fromExisting
    },
    attrs: {
      "data-toggle": "tab",
      "href": "#"
    },
    on: {
      "click": function click($event) {
        return _vm.toggleExisting(false);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Add New Person")))])])])])]), _vm.fromExisting ? _c('div', {
    staticClass: "from row"
  }, [_c('validation-provider', {
    staticClass: "col-12",
    attrs: {
      "name": "contact person",
      "vid": "contact person",
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-select', {
          attrs: {
            "error-messages": errors,
            "class-name": "mb-0",
            "label": "".concat(_vm.$t('Contact person'), "*"),
            "searchable": "",
            "options": _vm.physicalPersonsComputed
          },
          model: {
            value: _vm.form.id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "id", $$v);
            },
            expression: "form.id"
          }
        })];
      }
    }], null, false, 88060711)
  })], 1) : _c('div', {
    staticClass: "from row"
  }, [_c('validation-provider', {
    staticClass: "col-12",
    attrs: {
      "name": _vm.$t('contact logo'),
      "vid": "avatar_tus",
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('avatar-input', {
          attrs: {
            "error-messages": errors,
            "horizontal": "",
            "label": "Logo"
          },
          on: {
            "change": function change(tusId) {
              _vm.form.avatar_tus = tusId;
            }
          }
        })];
      }
    }])
  }), _c('validation-provider', {
    staticClass: "col-12",
    attrs: {
      "name": _vm.$t('Name'),
      "vid": "name",
      "rules": "required",
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "".concat(_vm.$t('Name'), "*"),
            "horizontal": "",
            "error-messages": errors
          },
          model: {
            value: _vm.form.name,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "name", $$v);
            },
            expression: "form.name"
          }
        })];
      }
    }])
  }), _c('validation-provider', {
    staticClass: "col-12",
    attrs: {
      "name": _vm.$t('Surname'),
      "vid": "surname",
      "rules": "required",
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('euro-input', {
          attrs: {
            "label": "".concat(_vm.$t('Surname'), "*"),
            "horizontal": "",
            "error-messages": errors
          },
          model: {
            value: _vm.form.surname,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "surname", $$v);
            },
            expression: "form.surname"
          }
        })];
      }
    }])
  }), _c('validation-provider', {
    staticClass: "col-12",
    attrs: {
      "name": _vm.$t('Gender'),
      "rules": "required",
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('euro-radio', {
          attrs: {
            "options": _vm.GENDERS,
            "label": "".concat(_vm.$t('Gender'), "*"),
            "horizontal": "",
            "error-messages": errors
          },
          model: {
            value: _vm.form.gender,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "gender", $$v);
            },
            expression: "form.gender"
          }
        })];
      }
    }])
  }), _c('validation-provider', {
    ref: "emailValidator",
    staticClass: "col-12",
    attrs: {
      "rules": _vm.rules.contact_email,
      "name": _vm.$t('Email'),
      "vid": "extraemail_set",
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('p', [_vm._v(_vm._s(_vm.$t("Email")) + " ")]), _c('multi-emails-input', {
          attrs: {
            "error-messages": errors,
            "horizontal": "",
            "options": _vm.EMAIL_KINDS
          },
          model: {
            value: _vm.form.extraemail_set,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "extraemail_set", $$v);
            },
            expression: "form.extraemail_set"
          }
        })];
      }
    }])
  }), _c('validation-provider', {
    ref: "phoneValidator",
    staticClass: "col-12 mt-5",
    attrs: {
      "rules": _vm.rules.contact_phone,
      "name": _vm.$t('Phone'),
      "vid": "telcontact_set",
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('p', [_vm._v(_vm._s(_vm.$t("Phone")))]), _c('multi-telephones-input', {
          attrs: {
            "error-messages": errors,
            "options": _vm.TELEPHONE_KINDS,
            "default-country": _vm.form.country_of_establishment
          },
          model: {
            value: _vm.form.telcontact_set,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "telcontact_set", $$v);
            },
            expression: "form.telcontact_set"
          }
        })];
      }
    }])
  })], 1), !_vm.showAddRole ? _c('div', {
    staticClass: "from row"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    ref: "roleValidator",
    attrs: {
      "name": _vm.$t('Company Role'),
      "vid": "Role",
      "rules": _vm.rules.role_required
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('euro-select', {
          attrs: {
            "options": _vm.computedRoles,
            "label": "".concat(_vm.$t('Company Role'), "*"),
            "error-messages": errors
          },
          model: {
            value: _vm.form.groups,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "groups", $$v);
            },
            expression: "form.groups"
          }
        })];
      }
    }], null, false, 4018093574)
  }), _c('b-btn', {
    attrs: {
      "variant": "light-primary",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.showFormAddRole();
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon menu-icon"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Code/Plus.svg"
    }
  })], 1), _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t("Add New Company Role")))])])], 1)]) : _c('div', {
    staticClass: "form row"
  }, [_vm._m(1), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "card p-5"
  }, [_c('h3', {
    staticClass: "font-weight-bolder text-dark-80 font-size-h5"
  }, [_vm._v(_vm._s(_vm.$t("Create new role")))]), _c('validation-observer', {
    ref: "validationObserverRole",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var handleSubmit = _ref9.handleSubmit;
        return [_c('validation-provider', {
          attrs: {
            "name": "name",
            "vid": "name",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref10) {
              var errors = _ref10.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": _vm.$t('Name*'),
                  "error-messages": errors
                },
                model: {
                  value: _vm.role.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.role, "name", $$v);
                  },
                  expression: "role.name"
                }
              })];
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "name": "description",
            "vid": "description"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref11) {
              var errors = _ref11.errors;
              return [_c('euro-input', {
                attrs: {
                  "label": _vm.$t('Description'),
                  "error-messages": errors
                },
                model: {
                  value: _vm.role.description,
                  callback: function callback($$v) {
                    _vm.$set(_vm.role, "description", $$v);
                  },
                  expression: "role.description"
                }
              })];
            }
          }], null, true)
        }), _c('div', {
          staticClass: "d-flex justify-content-end"
        }, [_c('b-button', {
          staticClass: "mr-2",
          class: {
            'spinner spinner-light spinner-right': _vm.isSaving
          },
          attrs: {
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              return handleSubmit(_vm.saveRole);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Create Role")) + " ")]), _c('b-button', {
          on: {
            "click": _vm.hideFormAddRole
          }
        }, [_vm._v(_vm._s(_vm.$t("Cancel")))])], 1)];
      }
    }])
  })], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-dashed mb-8 mt-6"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-dashed mb-8 mt-6"
  })]);

}]

export { render, staticRenderFns }