import api from "../api.service";

class CompanyContactRolesService {
  get PATH() {
    return "company-contactroles";
  }

  async getAll({
                 perPage,
                 page,
                 sortBy,
                 search,
                 sortDesc,
                 fields = null,
                 omit = null,
               }) {
    const offset = (page - 1) * perPage;

    if (sortDesc) {
      sortBy = "-" + sortBy;
    }

    const res = await api.get(`${this.PATH}/`, {
      params: {
        limit: perPage,
        offset,
        ordering: sortBy,
        fields: fields,
        omit: omit,
        search,
      },
    });

    return res.data;
  }

  async getOne(id) {
    const res = await api.get(`${this.PATH}/${id}/`);
    return res.data;
  }

  async create(body) {
    const res = await api.post(`${this.PATH}/`, body);
    return res.data;
  }
}

export default new CompanyContactRolesService();