import api from "../api.service";
import addressService from "../address.service";


class PhysicalPersonDetailService {
  async getGenders() {
    const res = await api.options("/physicalperson-detail/");
    // fix gender NA
    return res.data.actions.POST.gender.choices.filter(item => item.value != 'NA');
  }
  async getMaritalStatus() {
    const res = await api.options("/physicalperson-detail/");
    return res.data.actions.POST.marital_status.choices
  }
  async getOne(id) {
    const res = await api.get(`/physicalperson-detail/${id}`);
    return res.data;
  }

  async getStatoChoices() {
    const res = await api.options("/physicalperson-detail");
    return res.data.actions.POST.stato.choices;
  }

  async update(id, detail) {
    const {
      place_birth
    } = detail;

    if (place_birth) {
      const convertedAddresses = await addressService.getOrCreateAddress([{
        address: place_birth,
        manual_address: false
      }]);
      detail.place_birth = convertedAddresses[0].id;
    }

    const res = await api.patch(`/physicalperson-detail/${id}/`, detail);
    return res.data;
  }
}

export default new PhysicalPersonDetailService();