import api from "../api.service";

class CompanyEmailService {
  async getKinds() {
    const res = await api.options("company-contacts-email/");
    return res.data.actions.POST.kind.choices;
  }

  async getAllByCompany(companyID) {
    const res = await this.getAllByCompanyPaginated(companyID, 1000, 0);
    return res.results;
  }

  async getAllByCompanyPaginated(companyID, limit, offset) {
    const res = await api.get("company-contacts-email/", {
      params: { company: companyID, limit, offset },
    });
    return res.data;
  }

  async create(email) {
    const res = await api.post("company-contacts-email/", email);
    return res.data;
  }

  async delete(email) {
    await api.delete(`company-contacts-email/${email.id}`);
  }

  async setMain(email) {
    const res = await api.post(`company-contacts-email/${email.id}/set_main/`, email);
    return res.data;
  }
}

export default new CompanyEmailService();
