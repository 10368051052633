<template>
  <div>
    <div class="from row">
      <div class="col-12 mb-8">
        <ul class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
          role="tablist">
          <li class="nav-item">
            <a class="nav-link" :class="{ active: fromExisting }" data-toggle="tab" href="#"
              @click="toggleExisting(true)">{{ $t("Select From Database") }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ active: !fromExisting }" data-toggle="tab" href="#"
              @click="toggleExisting(false)">{{ $t("Add New Person") }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="fromExisting" class="from row">
      <validation-provider v-slot="{ errors }" name="contact person" vid="contact person" tag="div" class="col-12">
        <!-- rules="required" -->
        <euro-select v-model="form.id" :error-messages="errors" class-name="mb-0" :label="`${$t('Contact person')}*`"
          searchable :options="physicalPersonsComputed"></euro-select>
      </validation-provider>
    </div>
    <div v-else class="from row">
      <validation-provider v-slot="{ errors }" :name="$t('contact logo')" vid="avatar_tus" tag="div" class="col-12">
        <avatar-input :error-messages="errors" horizontal label="Logo" @change="tusId => {
              form.avatar_tus = tusId;
            }
              "></avatar-input>
      </validation-provider>

      <validation-provider v-slot="{ errors }" :name="$t('Name')" vid="name" rules="required" tag="div" class="col-12">
        <euro-input v-model="form.name" :label="`${$t('Name')}*`" horizontal :error-messages="errors"></euro-input>
      </validation-provider>

      <validation-provider v-slot="{ errors }" :name="$t('Surname')" vid="surname" rules="required" tag="div"
        class="col-12">
        <euro-input v-model="form.surname" :label="`${$t('Surname')}*`" horizontal
          :error-messages="errors"></euro-input>
      </validation-provider>

      <validation-provider v-slot="{ errors }" :name="$t('Gender')" rules="required" tag="div" class="col-12">
        <euro-radio v-model="form.gender" :options="GENDERS" :label="`${$t('Gender')}*`" horizontal
          :error-messages="errors"></euro-radio>
      </validation-provider>

      <validation-provider ref="emailValidator" v-slot="{ errors }" :rules="rules.contact_email" :name="$t('Email')"
        vid="extraemail_set" tag="div" class="col-12">
        <p>{{ $t("Email") }} </p>
        <multi-emails-input v-model="form.extraemail_set" :error-messages="errors" horizontal
          :options="EMAIL_KINDS"></multi-emails-input>
      </validation-provider>

      <validation-provider ref="phoneValidator" v-slot="{ errors }" :rules="rules.contact_phone" :name="$t('Phone')"
        vid="telcontact_set" tag="div" class="col-12 mt-5">
        <p>{{ $t("Phone") }}</p>
        <multi-telephones-input v-model="form.telcontact_set" :error-messages="errors" :options="TELEPHONE_KINDS"
          :default-country="form.country_of_establishment"></multi-telephones-input>
      </validation-provider>
    </div>
    <div v-if="!showAddRole" class="from row">
      <div class="col-12">
        <div class="separator separator-dashed mb-8 mt-6"></div>
      </div>
      <div class="col-12">
        <validation-provider ref="roleValidator" v-slot="{ errors }" :name="$t('Company Role')" vid="Role"
          :rules="rules.role_required">
          <euro-select v-model="form.groups" :options="computedRoles" :label="`${$t('Company Role')}*`"
            :error-messages="errors"></euro-select>
        </validation-provider>
        <b-btn variant="light-primary" block @click="showFormAddRole()">
          <span class="svg-icon menu-icon">
            <inline-svg src="/media/svg/icons/Code/Plus.svg" />
          </span>
          <span class="font-weight-bolder">{{ $t("Add New Company Role") }}</span>
        </b-btn>
      </div>
    </div>
    <div v-else class="form row">
      <div class="col-12">
        <div class="separator separator-dashed mb-8 mt-6"></div>
      </div>
      <div class="col-12">
        <div class="card p-5">
          <h3 class="font-weight-bolder text-dark-80 font-size-h5">{{ $t("Create new role") }}</h3>
          <validation-observer ref="validationObserverRole" v-slot="{ handleSubmit }">
            <validation-provider v-slot="{ errors }" name="name" vid="name" rules="required">
              <euro-input v-model="role.name" :label="$t('Name*')" :error-messages="errors"></euro-input>
            </validation-provider>

            <validation-provider v-slot="{ errors }" name="description" vid="description">
              <euro-input v-model="role.description" :label="$t('Description')" :error-messages="errors"></euro-input>
            </validation-provider>

            <div class="d-flex justify-content-end">
              <b-button variant="success" class="mr-2" :class="{ 'spinner spinner-light spinner-right': isSaving }"
                @click="handleSubmit(saveRole)">
                {{ $t("Create Role") }}
              </b-button>
              <b-button @click="hideFormAddRole">{{ $t("Cancel") }}</b-button>
            </div>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyTelephoneService from "@/core/services/company/company-telephone.service";
import CompanyEmailService from "@/core/services/company/company-email.service";
import PhysicalPersonDetailService from "@/core/services/physical-person/physical-person-detail.service";
import PhysicalPersonService from "@/core/services/physical-person/physical-person.service";
import CompanyContactRolesService from "@/core/services/company/company-contactroles.service";
import { mapState } from "vuex";
import { backendErrorSwal } from "@/core/helpers/swal";

export default {
  props: {
    existingForm: {
      type: Object,
      default: null,
    },
    roles: {
      type: Array,
      default: () => [],
    },
    contacts: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      form: {
        id: null,
        avatar_tus: null,
        name: "",
        surname: "",
        telcontact_set: [],
        extraemail_set: [],
        gender: "MA",
        groups: "",
      },
      TELEPHONE_KINDS: [],
      EMAIL_KINDS: [],
      GENDERS: [],
      physicalPersons: [],
      existingPerson: null,
      fromExisting: true,
      isSaving: false,
      showAddRole: false,
      role: {
        name: "",
        description: "",
      },
    };
  },

  computed: {
    ...mapState("user", ["user"]),
    physicalPersonsComputed() {
      return this.physicalPersons.filter(el => !this.contacts.includes(el.id)).map(el => ({ text: `${el.name} ${el.surname}`, value: el.id }));
    },
    computedRoles() {
      return this.roles.map(el => ({ text: el.name, value: el.id }));
    },
    rules() {
      return {
        contact_email: { required: !this.form.telcontact_set.length || !this.form.telcontact_set.filter(el => el.number != '').length },
        contact_phone: { required: !this.form.extraemail_set.length || !this.form.extraemail_set.filter(el => el.email != '').length },
        role_required: { required: !!this.form.id || !!this.form.name },
      };
    },
  },

  watch: {
    form: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.groups) {
          const role = this.computedRoles.find(el => el.value === val.groups);
          let foundRoleName = "";
          if (role) foundRoleName = role.text;
          if (this.fromExisting) {
            let foundName = "";
            const name = this.physicalPersonsComputed.find(el => el.value === val.id);
            if (name) foundName = name.text;
            this.$emit("input", { id: val.id, role: val.groups, name: foundName, roleName: foundRoleName });
          } else {
            const newVal = { ...val };
            delete newVal.id;
            this.$emit("input", { ...newVal, roleName: foundRoleName, role: this.form.groups });
          }
        }
      },
    },
    fromExisting: {
      handler() {
        const validator = this.$refs.roleValidator;
        if (validator) {
          validator.reset();
        }
        if (this.form.groups) {
          const role = this.computedRoles.find(el => el.value === this.form.groups);
          let foundRoleName = "";
          if (role) foundRoleName = role.text;
          if (this.fromExisting) {
            let foundName = "";
            const name = this.physicalPersonsComputed.find(el => el.value === this.form.id);
            if (name) foundName = name.text;
            this.$emit("input", { id: this.form.id, role: this.form.groups, name: foundName, roleName: foundRoleName });
          } else {
            const newVal = { ...this.form };
            delete newVal.id;
            this.$emit("input", { ...newVal, roleName: foundRoleName });
          }
        }
      },
    },
  },

  async created() {
    try {
      const [telephoneKinds, emailKinds, genders] = await Promise.all([
        CompanyTelephoneService.getKinds(),
        CompanyEmailService.getKinds(),
        PhysicalPersonDetailService.getGenders(),
      ]);
      this.TELEPHONE_KINDS = telephoneKinds.map(x => ({ value: x.value, text: x.display_name }));
      this.EMAIL_KINDS = emailKinds.map(x => ({ value: x.value, text: x.display_name }));
      this.GENDERS = genders.map(el => ({ value: el.value, text: el.display_name }));
    } catch (err) {
      console.error(err);
    }
  },

  mounted() {
    if (this.existingForm) {
      this.form = { ...this.form, ...this.existingForm };
    }
    PhysicalPersonService.getAllNotPaginated(true).then(res => {
      this.physicalPersons = res.results;
    });
  },

  methods: {
    handleRoleCreation(newRole) {
      this.roles.push(newRole);
      this.form.groups = newRole.id;
    },
    resetForm() {
      this.form = {
        id: null,
        avatar_tus: null,
        name: "",
        surname: "",
        telcontact_set: [],
        extraemail_set: [],
        gender: "MA",
        groups: "",
      };
    },
    toggleExisting(val) {
      this.resetForm();
      this.fromExisting = val;
    },
    async saveRole() {
      this.isSaving = true;
      try {
        const body = { ...this.role, public: this.user.is_ap_staff };
        const res = await CompanyContactRolesService.create(body);
        this.handleRoleCreation(res);
      } catch (err) {
        if (err?.response?.status === 400) {
          this.apiErrors = err.response.data;
        } else {
          backendErrorSwal(err, err?.response?.data?.detail);
        }
      } finally {
        this.isSaving = false;
        this.hideFormAddRole();
      }
    },
    showFormAddRole() {
      this.showAddRole = true;
    },
    hideFormAddRole() {
      this.showAddRole = false;
    },


  },
};
</script>
