import api from "../api.service";

class CompanyTelephoneService {
  async getKinds() {
    const res = await api.options("company-contacts-tel/");
    return res.data.actions.POST.kind.choices;
  }

  async getAllByCompany(companyID) {
    const res = await this.getAllByCompanyPaginated(companyID, 1000, 0);
    return res.results;
  }

  async getAllByCompanyPaginated(companyID, limit, offset) {
    const res = await api.get("company-contacts-tel/", {
      params: { company: companyID, limit, offset },
    });
    return res.data;
  }

  async create(telephone) {
    const res = await api.post("company-contacts-tel/", telephone);
    return res.data;
  }

  async delete(telephone) {
    await api.delete(`company-contacts-tel/${telephone.id}`);
  }

  async setMain(telephone) {
    telephone.main = true
    const res = await api.post(`company-contacts-tel/${telephone.id}/set_main/`, telephone);
    return res.data;
  }
}

export default new CompanyTelephoneService();
